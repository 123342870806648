<template>
  <LayoutVisiteur>
    <section class="container-fluid bg-light">
      <div class="row p-2 p-lg-5">
        <div class="col-12 col-lg-4 d-none d-lg-block"></div>
        <div class="col-12 col-lg-4 bg-secondary shadow text-light rounded p-4">
          <form
            class="row g-3"
            @submit.prevent="confirm"
            formnovalidate="formnovalidate"
          >
            <h2 class="text-primary">Se désabonner</h2>
            <hr />
            <div>Vous allez beaucoup nous manquer !</div>
            <div class="col-12">
              <label for="inputMail" class="form-label">Mail</label>
              <input
                type="email"
                class="form-control"
                id="inputMail"
                disabled
                v-model="email_visit"
              />
            </div>
            <div class="col-12 mb-2 mt-4 text-end">
              <button
                v-if="loading"
                class="btn btn-primary"
                type="button"
                disabled
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Envoi...
              </button>
              <button
                v-else
                type="submit"
                class="btn btn-primary"
                formnovalidate="formnovalidate"
              >
                Envoyer
                <!-- <IcoSend /> -->
              </button>
            </div>
          </form>
        </div>
        <div class="col-12 col-lg-4 d-none d-lg-block"></div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import { success, error } from "../../utils/toast";
import { deleteNewsLetter } from "../../api/newsLetter";
import { decodeToken } from "../../utils/decodeToken";

export default {
  name: "MdpForget",
  components: {
    LayoutVisiteur,
  },
  data() {
    return {
      email_visit: "",
      loading: false,
    };
  },
  mounted() {
    try {
      const decodeV = decodeToken(this.$route.params.token);
      if (decodeV) {
        //console.log(decodeV);
        this.email_visit = decodeV.email_visit;
      }
    } catch (err) {
      this.$router.push(this.$route.query.redirect || "/");
    }
  },
  methods: {
    confirm() {
      this.loading = true;
      deleteNewsLetter(this.email_visit)
        .then((result) => {
          if (result.data.error) {
            //console.log(result.data.error);
            error(result.data.error);
            this.loading = false;
          } else {
            this.loading = false;
            success("Vous êtes maintenant désabonné à Mk-co");
            //console.log(result);
          }
        })
        .catch((/*err*/) => {
          //console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
